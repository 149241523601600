
import popupNew from "./popupNew.vue";
import { defineComponent } from "vue";
import {
  VisitorTrainControllerPage,
  VisitorTrainController_del,
  VisitorTrainControllerUpdateStatus,
} from "@/api/api";
const columns1 = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: { index: number }) => row.index + 1,
  },
  {
    title: "培训名称：",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "发布人",
    key: "publisher",
    dataIndex: "publisher",
  },
  {
    title: "发布时间",
    key: "releaseTime",
    dataIndex: "releaseTime",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width:200,
    slots: { customRender: "operation" },
  },
];
const columns2 = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row: { index: number }) => row.index + 1,
  },
  {
    title: "培训名称：",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "发布人",
    key: "publisher",
    dataIndex: "publisher",
  },
  {
    title: "发布时间",
    key: "releaseTime",
    dataIndex: "releaseTime",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width:300,
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "index",
  data() {
    return {
      sorter:'createdTime:desc',
      spinning: false,
      selectedRowKeys: [],
      sourceData1: [] as any[],
      sourceData2: [] as any[],
      columns1,
      columns2,
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["1", "20", "30", "50"], // 每页数量选项
      },
    };
  },
  components: {
    popupNew,
  },
  mounted() {
    this.init();
  },
  methods: {
    release(visitorTrainId: any, status: number) {
      VisitorTrainControllerUpdateStatus({
        visitorTrainId2: this.sourceData1[0]?.visitorTrainId,
        visitorTrainId: visitorTrainId,
        status: status,
      }).then((res) => {
        this.refresh();
      });
    },
    del(val: number, visitorTrainId: any) {
      if (val == 1) {
        VisitorTrainController_del({
          ids: this.selectedRowKeys.join(","),
        }).then((res) => {
          this.refresh();
        });
      }
      if (val == 2) {
        const params = [];
        params.push(visitorTrainId);
        VisitorTrainController_del({ ids: params.join(",") }).then((res) => {
          this.refresh();
        });
      }
    },
    edit(record: any) {
      let myRef: any = this.$refs.popupNew;
      myRef.showModal("编辑进场施工培训", record.visitorTrainId);
    },
    init() {
      const params = {
        currentPage: this.pagination.current,
        pageSize: this.pagination.pageSize,
        type: 1,
      };
      this.spinning = true;
      this.sourceData1 = [];
      this.sourceData2 = [];
      VisitorTrainControllerPage({currentPage:1,pageSize:10,type: 1,sort:this.sorter,status:1}).then((res: any) => {
        this.sourceData1=res.data
      });
      VisitorTrainControllerPage({...params,sort:this.sorter,status:0}).then((res: any) => {
        this.sourceData2=res.data
        this.pagination.total=res.totalCount
        this.spinning = false;
      });
    },
    refresh() {
      this.init();
      this.selectedRowKeys = [];
    },
    add() {
      let myRef: any = this.$refs.popupNew;
      myRef.showModal("新增进场施工培训");
    },
    onSelectChange(selectedRowKeys: any) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onChange(pagination:any, filters: [], sorter: any){
      this.pagination.current=pagination.current
      this.pagination.pageSize=pagination.pageSize
      this.sorter='createdTime:desc'
      if(sorter.order=='ascend'){
         this.sorter=sorter.columnKey+':asc'
      }else if(sorter.order=='descend'){
        this.sorter=sorter.columnKey+':desc'
      }
      this.init()
    }
  },
});
